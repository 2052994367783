//------------------------------------------------------------------
//	BOOTSTRAP SETUP
//------------------------------------------------------------------
@import 'common/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/badge';

//------------------------------------------------------------------
//	CORE ADMIN STYLES
//------------------------------------------------------------------
@import 'node_modules/mlmi-library/components/admin/v1/admin';

.vertical-center {
  display: flex;
  align-items: center;
}
